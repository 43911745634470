import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Editorial" />
      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <h1 style={{ textAlign: `center` }}>Editorial</h1>
      </div>
      <div className="portfolio-grid">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const inCategory =
            !!node.frontmatter.categories &&
            Array.isArray(node.frontmatter.categories) &&
            node.frontmatter.categories.includes("editorial")

          if (node.frontmatter.type !== `portfolio` || !inCategory) {
            return false
          }
          let videoUrl = `${node.frontmatter.video}?title=0&amp;byline=0&amp;portrait=0`
          return (
            <article key={node.fields.slug} className="portfolio-entry">
              <div className="video-wrapper">
                <iframe
                  src={videoUrl}
                  width="100%"
                  height="316"
                  frameBorder="0"
                  title={title}
                ></iframe>
              </div>
              <header>
                <h3
                  style={{
                    marginTop: rhythm(1 / 4),
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  {title}
                </h3>
              </header>
              <section>
                {node.frontmatter.tags.map(a => `${a}`).join(" | ")}
              </section>
            </article>
          )
        })}
      </div>
      <div style={{ textAlign: `center` }}>
        <Link to={`/portfolio`} className="button">
          Back to Portfolio
        </Link>
      </div>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            type
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            video
            categories
          }
        }
      }
    }
  }
`
